<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 pt-4>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Name</th>
                <th class="text-left text-uppercase">Sum(INR)</th>
                <th class="text-left text-uppercase">Total Item(s)</th>
                <th class="text-left text-uppercase">Total Payment(s)</th>
                <!-- <th class="text-left">Reserved Seats</th>
                <th class="text-left">Free</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in bookingData" :key="i">
                <td>{{ item.data.guest.name }}</td>
                <td>INR {{ item.sum }}</td>
                <td>{{ item.itemCount }}</td>
                <td>{{ item.paymentCount }}</td>
                <!-- <td>{{ item.reservedCount }}</td>
                <td>{{ item.freeCount }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <!-- <v-flex xs12 md12 pt-4>
        <span
          style="font-family: poppinsbold; font-size: 18px; color: #53a874"
        >
          Ticket #{{ item.ticket.ticketNo }}
        </span>
      </v-flex>
      <v-flex xs12 md12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                  src="../../assets/images/no-image.png"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.programme.progName }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md2 text-left>
                      <span class="pageForm"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>
                    <v-flex md3 text-left>
                      <span class="pageForm"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>
                    <v-flex md4 text-left>
                      <span class="pageForm"> Paid By</span><br />
                      <span class="cardSubHeading"  style="letter-spacing: 0px">
                        {{ item.user.email }}
                      </span>
                      <br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.user.phone }}
                      </span>
                    </v-flex>
                    <v-flex md2 text-center v-if="item.isCottage">
                      <span class="pageForm"> Rooms Required</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.numberOfRooms }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex py-2 xs12 v-for="(items, i) in item.guest" :key="i">
                  <v-layout wrap>
                    <v-flex md2 text-capitalize>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.name }}
                      </span>
                    </v-flex>
                    <v-flex md3>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.email }}
                      </span>
                    </v-flex>

                    <v-flex md2>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.phoneno }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.gender }}
                      </span>
                    </v-flex>
                    <v-flex md2>
                      <span class="pageForm" style="font-size: 14px">
                        {{ formatDate(items.dob) }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.nationality }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span
                        v-if="items.nationality == 'Indian'"
                        class="pageForm"
                        style="font-size: 14px"
                      >
                        No Id Proof
                      </span>
                      <span v-else class="pageForm" style="font-size: 14px">
                        {{ items.idproof }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex md4>
                  <span class="textField2" style="font-size: 14px">
                    Program Total
                  </span>
                </v-flex>
                <v-flex md7 text-right align-self-end>
                  <span style="font-family:poppinssemibold; font-size: 16px">
                    {{ item.programTotal }} INR
                  </span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["bookingData", "pages","fromDate","toDate"],
  // props: ["fromDate","toDate","programme"],
  data() {
    return {
      currentPage: 1,
      // pages: 0,
      // bookingData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  // watch: {
  //   currentPage() {
  //     this.winStepper();
  //   },
  // },

  watch: {
    fromDate() {
      // this.getData()
    },
    toDate() {
      // this.getData()
    },
    programme() {
      // this.getData()
    },
    currentPage() {
      // this.getData();
      this.winStepper();
    },
  },
  beforeMount() {
    // this.getData()
    this.winStepper();
  },
  methods: {
    winStepper() {
      // console.log("step", this.currentPage);
      // alert("my call win comp")
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
        type: "Booking",
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/agentconsolidatedbookingreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          // user: this.programme,
          // slotDetail: this.slotDetail,
          count: 12,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.bookingData = response.data.data;
            // this.reservationData = response.data.reservations;
            this.pages = response.data.pages;

            // this.excel = response.data.data;
            // this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>